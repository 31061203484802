@use 'styles/modules/Spacing';

.root {
  width: 100%;
}

.tooltip {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: Spacing.$unit;
}
