@use '@assuranceiq/react-components/styles/colors';
@use 'styles/modules/Spacing';

.show {
  height: 100%;

  :global {
    .RaShow-main {
      height: 100%;

      .MuiPaper-root {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.tabs {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  :global {
    .MuiTabs-root {
      padding: 0 Spacing.units(2);
    }

    .RaTabbedShowLayout-content {
      flex-grow: 1;
      padding: 0;
      background-color: colors.$bg-light;
    }
  }
}
