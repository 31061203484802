// TODO: Move this into ARC

$font-weight-regular: 400;
$font-weight-bold: 700;
$font-weight-semi-bold: 600;

// Font Sizes

$body-font-size: 16px;
$tiny-font-size: 12px;
$small-font-size: 14px;
$medium-font-size: 18px;
$large-font-size: 20px;
$xl-font-size: 24px;
$xxl-font-size: 36px;
$xxxl-font-size: 54px;

$body-line-height: 1.25;
$tiny-line-height: $body-line-height;
$small-line-height: $body-line-height;
$medium-line-height: $body-line-height;
$large-line-height: $body-line-height;
$xl-line-height: $body-line-height;
$xxl-line-height: $body-line-height;
$xxxl-line-height: $body-line-height;

$body-margin-bottom: 0.75em;
$tiny-margin-bottom: $body-margin-bottom;
$small-margin-bottom: $body-margin-bottom;
$medium-margin-bottom: $body-margin-bottom;
$large-margin-bottom: $body-margin-bottom;
$xl-margin-bottom: $body-margin-bottom;
$xxl-margin-bottom: $body-margin-bottom;
$xxxl-margin-bottom: $body-margin-bottom;

@mixin font-styles($font-size, $line-height, $margin-bottom: null) {
  font-size: $font-size;
  line-height: $line-height;
  @if $margin-bottom {
    margin-bottom: $margin-bottom;
  }
}

@mixin body-styles($gutter-bottom: true) {
  $margin-bottom: $body-margin-bottom;
  @if $gutter-bottom == false {
    $margin-bottom: null;
  }
  @include font-styles($body-font-size, $body-line-height, $margin-bottom);
}
@mixin tiny-styles($gutter-bottom: true) {
  $margin-bottom: $tiny-margin-bottom;
  @if $gutter-bottom == false {
    $margin-bottom: null;
  }
  @include font-styles($tiny-font-size, $tiny-line-height, $margin-bottom);
}

@mixin small-styles($gutter-bottom: true) {
  $margin-bottom: $small-margin-bottom;
  @if $gutter-bottom == false {
    $margin-bottom: null;
  }
  @include font-styles($small-font-size, $small-line-height, $margin-bottom);
}

@mixin medium-styles($gutter-bottom: true) {
  $margin-bottom: $medium-margin-bottom;
  @if $gutter-bottom == false {
    $margin-bottom: null;
  }
  @include font-styles($medium-font-size, $medium-line-height, $margin-bottom);
}

@mixin large-styles($gutter-bottom: true) {
  $margin-bottom: $large-margin-bottom;
  @if $gutter-bottom == false {
    $margin-bottom: null;
  }
  @include font-styles($large-font-size, $large-line-height, $margin-bottom);
}

@mixin xl-styles($gutter-bottom: true) {
  $margin-bottom: $xl-margin-bottom;
  @if $gutter-bottom == false {
    $margin-bottom: null;
  }
  @include font-styles($xl-font-size, $xl-line-height, $margin-bottom);
}

@mixin xxl-styles($gutter-bottom: true) {
  $margin-bottom: $xxl-margin-bottom;
  @if $gutter-bottom == false {
    $margin-bottom: null;
  }
  @include font-styles($xxl-font-size, $xxl-line-height, $margin-bottom);
}

@mixin xxxl-styles($gutter-bottom: true) {
  $margin-bottom: $xxxl-margin-bottom;
  @if $gutter-bottom == false {
    $margin-bottom: null;
  }
  @include font-styles($xxxl-font-size, $xxxl-line-height, $margin-bottom);
}

:export {
  fontWeightRegular: $font-weight-regular;
  fontWeightBold: $font-weight-bold;
  fontWeightSemiBold: $font-weight-semi-bold;

  bodyFontSize: $body-font-size;
  bodyLineHeight: $body-line-height;
  bodyMarginBottom: $body-margin-bottom;

  tinyFontSize: $tiny-font-size;
  tinyLineHeight: $tiny-line-height;
  tinyMarginBottom: $tiny-margin-bottom;

  smallFontSize: $small-font-size;
  smallLineHeight: $small-line-height;
  smallMarginBottom: $small-margin-bottom;

  mediumFontSize: $medium-font-size;
  mediumLineHeight: $medium-line-height;
  mediumMarginBottom: $medium-margin-bottom;

  largeFontSize: $large-font-size;
  largeLineHeight: $large-line-height;
  largeMarginBottom: $large-margin-bottom;

  xlFontSize: $xl-font-size;
  xlLineHeight: $xl-line-height;
  xlMarginBottom: $xl-margin-bottom;

  xxlFontSize: $xxl-font-size;
  xxlLineHeight: $xxl-line-height;
  xxlMarginBottom: $xxl-margin-bottom;

  xxxlFontSize: $xxxl-font-size;
  xxxlLineHeight: $xxxl-line-height;
  xxxlMarginBottom: $xxxl-margin-bottom;
}
