@use '@assuranceiq/react-components/styles/colors';

@use 'styles/modules/Spacing';
@use 'styles/modules/Typography';

$root-box-shadow-color: change-color(colors.$gray-60, $alpha: 0.16);
$root-box-shadow: 0px 1px 8px 0px $root-box-shadow-color, 0px 2px 4px 0px $root-box-shadow-color,
  0px 2px 2px 0px $root-box-shadow-color;

$content-padding-inline: Spacing.units(3);
$content-padding-block: Spacing.units(2);

// Gap between content and details in the expanded state
$content-gap: Spacing.units(1);

$follow-ups-button-gap: Spacing.units(0.5);
$follow-ups-button-icon-size: 0.75em;

$action-button-padding-inline: Spacing.units(1.5);
$action-button-padding-block: Spacing.units(1);
$action-padding-inline: $content-padding-inline - $action-button-padding-inline;
$action-padding-block: $content-padding-block;
$action-icon-size: 1.25em;
$action-icon-color: colors.$text-color;

// TODO: Add to ARC
$yellow-50: #fce19c;
$card-icon-background-color: $yellow-50;
$card-icon-color: colors.$bg-default;
$card-icon-circle-size: 1.5em;
$card-icon-circle-size-px: Spacing.units(3); // for calculations
$card-icon-size: 0.6em;

$details-margin-top: $content-gap - $content-padding-block;
$details-padding-right: Spacing.units(3);
$details-padding-bottom: Spacing.units(2);
$details-padding-left: $details-padding-right + $card-icon-circle-size-px + Spacing.units(1);

.root {
  border: none;
  box-shadow: $root-box-shadow;
}

.content {
  display: flex;
  flex-direction: column;
  gap: Spacing.units(1);
  padding: $content-padding-block $content-padding-inline;
}

.cardMarker {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $card-icon-circle-size;
  height: $card-icon-circle-size;
  border-radius: 100%;
  background-color: $card-icon-background-color;

  :global .MuiSvgIcon-root {
    fill: $card-icon-color;
    font-size: $card-icon-size;
  }
}

.chanceToClose {
  display: flex;
  align-items: center;
}

.actions {
  padding: $action-padding-block $action-padding-inline;

  :global {
    .MuiButton-root {
      padding: $action-button-padding-block $action-button-padding-inline;
      min-width: auto;
    }

    .MuiButton-startIcon {
      margin: 0;
    }

    .MuiSvgIcon-root {
      fill: $action-icon-color;
      font-size: $action-icon-size;
    }
  }
}

:global {
  :local(.collapseButton) {
    align-items: baseline;
    gap: $follow-ups-button-gap;
    color: colors.$text-color;

    // HACK: This is a workaround for the MUI button icon alignment with the text above
    margin-left: -10px;

    &.Mui-disabled {
      // Override MUI’s default disabled color; for consistency with other opportunity cards
      color: colors.$text-color;

      .MuiButton-endIcon {
        visibility: hidden; // Hide caret when disabled, but still take up space (for alignment)
      }
    }

    .MuiSvgIcon-root {
      font-size: $follow-ups-button-icon-size;
    }
  }
}

.createFollowUpButton {
  @include Typography.small-styles($gutter-bottom: false);
  border: none;

  &:hover {
    border: none;
  }
}

.details {
  margin-top: $details-margin-top;
  padding: 0 $details-padding-right $details-padding-bottom $details-padding-left;

  // Reassign the last-child padding from MUI
  &:last-child {
    padding-bottom: $details-padding-bottom;
  }
}
