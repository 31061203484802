@use 'styles/modules/Spacing';

:global {
  :local(.button) {
    min-width: 0;
    padding-inline: Spacing.units(1);

    .MuiButton-startIcon {
      margin-inline: 0;
    }
  }
}
