// TODO: Move to ARC
$unit: 8px;

@function units($amount) {
  @return $unit * $amount;
}

:export {
  unit: $unit;
}
