@use '@assuranceiq/react-components/styles/colors';
@use 'styles/modules/Spacing';
@use 'styles/modules/Typography';

.layout {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  :global {
    #main-content {
      background-color: transparent;
    }

    .RaLayout-content .MuiToolbar-root {
      min-height: inherit;
    }
  }
}
