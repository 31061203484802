@use '@assuranceiq/react-components/styles/colors';
@use 'styles/modules/Spacing';
@use 'styles/modules/Typography';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.heading {
  font-weight: Typography.$font-weight-semi-bold;
  color: colors.$gray-100;
}

.message {
  color: colors.$text-muted;
  margin-bottom: Spacing.units(2);
}

.details {
  margin-block: Spacing.units(2);

  summary {
    cursor: pointer;
    list-style: none;
    color: colors.$text-muted;
  }
}

.infoIcon {
  position: relative;
  top: 0.2em; // Align with text
}

.stackTrace {
  padding: Spacing.units(2);
  border: 1px solid colors.$error-light;
  border-radius: Spacing.units(1/2);
  margin-block: Spacing.units(1);

  color: colors.$error-dark;
  text-align: left;

  pre {
    max-width: 100%;

    white-space: pre-wrap;
  }
}
