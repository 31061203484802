@use '@assuranceiq/react-components/styles/colors';
@use 'styles/modules/Spacing';

$loading-color: colors.$gray-100;

.root {
  position: relative;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: $loading-color;
  gap: Spacing.units(2);
}

.tooltip {
  top: 36px;
}
