@use '@assuranceiq/react-components/styles/colors';
@use 'styles/modules/Spacing';
@use 'styles/modules/Typography';

$table-border-width: 1px;
$table-border-style: solid;
$table-border-color: colors.$border-light;

$table-cell-inline-padding: Spacing.units(2);

$column-separator-width: 2px;
$column-separator-height: 1em;
$column-separator-color: colors.$gray-60;

$active-sort-icon-color: colors.$text-color;
$inactive-sort-icon-color: colors.$gray-60;

:global {
  :local(.datagrid) {
    border: none;

    .MuiDataGrid-columnHeader,
    .MuiDataGrid-cell {
      padding-inline: $table-cell-inline-padding;
    }

    .MuiDataGrid-cell {
      // Ensure text always uses ellipsis overflow
      :has(.MuiTypography-root),
      .MuiTypography-root {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .MuiDataGrid-columnHeaders {
      background: colors.$bg-light;
      border-inline: $table-border-width $table-border-style transparent; // Match border inset of table below
      border-block: none;
    }

    .MuiDataGrid-pinnedColumnHeaders {
      background: colors.$bg-light;
    }

    .MuiDataGrid-columnHeaderTitle {
      font-weight: Typography.$font-weight-bold;
    }

    .MuiDataGrid-virtualScroller {
      background-color: colors.$white;
      border: $table-border-width $table-border-style $table-border-color;
    }
  }

  /** Column Separator Styling */
  :local(.datagrid) {
    // Custom column separator styles
    .MuiDataGrid-columnSeparator--resizable {
      &::after {
        display: block;
        content: '';
        opacity: 0;

        position: absolute;
        left: calc(50% - $column-separator-width / 2);

        height: $column-separator-height;

        border-right: $column-separator-width dotted $column-separator-color;
        transition: opacity 0.2s ease-in-out;
      }

      // Hide original icon
      .MuiSvgIcon-root {
        opacity: 0;
      }
    }

    // Display all column separators on header bar hover
    .MuiDataGrid-columnHeaders:hover .MuiDataGrid-columnSeparator--resizable::after {
      opacity: 1;
    }

    // Make sure last column separator is fully visible (not cut off by table edge)
    .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator--resizable::after {
      left: calc(50% - $column-separator-width * 2);
    }

    // Hide on last pinned column header
    .MuiDataGrid-pinnedColumnHeaders
      .MuiDataGrid-columnHeader:last-child
      .MuiDataGrid-columnSeparator--resizable::after {
      display: none;
    }
  }

  /** Column Sort Buttons */
  :local(.datagrid) {
    .MuiDataGrid-sortIcon {
      color: $inactive-sort-icon-color;
    }

    .MuiDataGrid-columnHeader--sorted .MuiDataGrid-sortIcon {
      color: $active-sort-icon-color;
    }

    .MuiDataGrid-columnHeader--sortable {
      padding-right: 0; // Remove padding to allow sort icon to be flush with right edge
    }

    // Display all sort icons on header bar hover
    .MuiDataGrid-columnHeaders:hover {
      .MuiDataGrid-columnHeader {
        .MuiDataGrid-iconButtonContainer {
          visibility: visible;
          width: auto;
        }

        .MuiDataGrid-sortIcon {
          opacity: 1;
        }
      }
    }
  }
}
