@use '@assuranceiq/react-components/styles/colors';

@use 'styles/modules/Spacing';
@use 'styles/modules/Typography';

// Using the same value as the react-admin's <TextInput> component
$react-admin-input-margin-top: Spacing.units(1);

$root-padding-bottom: Spacing.units(1);

$component-padding-inline: Spacing.units(2);
$component-padding-block: Spacing.units(1);
$component-border-radius: Spacing.units(1);
$component-border-color: colors.$border-light;
$component-font-size: Typography.$small-font-size;

$columns-min-width: 100px; // prevent size changing between different options

$filter-icon-color: colors.$gray-60;

$progress-size: Spacing.units(2.5);

.root {
  padding-bottom: $root-padding-bottom;
}

.columns {
  min-width: $columns-min-width;
  border-radius: $component-border-radius 0 0 $component-border-radius;
  margin-top: $react-admin-input-margin-top;
  font-size: $component-font-size;

  fieldset {
    border-color: $component-border-color;
    border-right-width: 0;
  }

  :global {
    .MuiInputBase-input {
      padding: $component-padding-block $component-padding-inline;
    }
  }
}

.columnOption {
  font-size: $component-font-size;
}

.input {
  &:hover {
    fieldset {
      border-left: none;
    }

    :global {
      .Mui-focused {
        fieldset {
          border-left: 1px solid colors.$primary;
        }
      }
    }
  }

  fieldset {
    border-color: $component-border-color;
  }

  :global {
    .MuiInputBase-root {
      font-size: $component-font-size;
      padding: 0 $component-padding-inline;
      border-radius: 0 $component-border-radius $component-border-radius 0;
    }
    .MuiInputBase-input {
      padding: $component-padding-block $component-padding-inline;
    }
  }
}

.searchIcon {
  color: $filter-icon-color;
  font-size: inherit;
}

.progress {
  color: $filter-icon-color;
  flex-shrink: 0;
}
