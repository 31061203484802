@use '@assuranceiq/react-components/styles/colors';
@use 'styles/modules/Spacing';

.callButton {
  min-width: 6em; // Ensure width is consistent between "DNC" and "Call" state

  :global {
    .MuiLoadingButton-loadingIndicatorStart {
      // Match button inline padding
      // SEE: https://github.com/assuranceiq/vega/blob/5930bcba/src/styles/theme/config/buttonsConfig.ts#L24
      left: Spacing.units(1) * 2;
    }
  }
}

:global {
  // HACK: Workaround for using :global with `&` that throws an error
  // SEE: https://github.com/css-modules/css-modules/issues/295#issuecomment-404873976
  :local(.error) {
    &.Mui-disabled {
      color: colors.$error;
      border-color: colors.$error;
    }
  }
}
