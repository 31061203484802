@use 'styles/modules/Spacing';

.alertMessage {
  flex-grow: 1;
}

.alertActions {
  flex-shrink: 0;
}

.closeBtn {
  margin-left: Spacing.units(2);
}

.customAction {
  padding-top: Spacing.units(0.5);
  text-transform: uppercase;
}
