@use '@assuranceiq/react-components/styles/colors';

@use 'styles/modules/Spacing';
@use 'styles/modules/Typography';

.tooltip {
  display: flex;
  align-items: center;
}

.tooltipIcon {
  display: flex;
  margin-left: Spacing.$unit;
  color: colors.$text-muted;
  font-size: Typography.$body-font-size;
}

.label {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
