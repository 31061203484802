@use '@assuranceiq/react-components/styles/colors';
@use 'styles/modules/Radius';
@use 'styles/modules/Spacing';

.disabledInputImitation {
  background-color: colors.$bg-light;
  padding: Spacing.units(1.5) Spacing.units(2);
  border-radius: Radius.$border-radius-default;
  border: 1px solid colors.$border-light;
  display: block;
}
