@use '@assuranceiq/react-components/styles/colors';

.grid {
  background-color: colors.$white;
  min-height: 100vh;
}

.container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  &.centered {
    align-items: center;
    justify-content: center;
  }
}
