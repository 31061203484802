@use 'styles/modules/Spacing';

$follow-ups-button-gap: Spacing.units(0.5);
$follow-ups-button-icon-size: 0.75em;

$action-button-padding-inline: Spacing.units(1.5);
$action-button-padding-block: Spacing.units(1);

.actions {
  :global {
    .MuiButton-root {
      padding: $action-button-padding-block $action-button-padding-inline;
      min-width: auto;
    }

    .MuiButton-startIcon {
      margin: 0;
    }
  }
}
