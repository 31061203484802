/// Fades an object in after a delay. Useful for optimistic loading
/// indicators—we don’t want to display them if the data loads quickly.
///
/// @param {string} $delay - The delay before the animation starts.
@mixin fadeInWithDelay($delay: 0.5s) {
  animation: fade-in 0.2s ease-in-out $delay forwards;
  opacity: 0;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
