@use '@assuranceiq/react-components/styles/colors';
@use 'styles/modules/Typography';
@use 'styles/modules/Shade';
@use 'styles/modules/Spacing';

$progress-step-circle-size: 1.625rem;

$progress-step-completed-circle-color: colors.$active-light-accent;
$progress-step-completed-text-color: colors.$active;

$progress-step-active-text-color: colors.$active;

$progress-step-next-circle-color: colors.$bg-light;
$progress-step-next-text-color: colors.$text-muted;

$progress-step-connector-line-width: 2px;

$toolbar-shadow: 0px 1px 8px 0px Shade.black(0.16);

.progressBar {
  // Unfortunately className for wizardForm not working, so it's the only way on how to reduce space from the bottom
  margin-bottom: Spacing.units(1) * -1;

  :global {
    .MuiStepLabel-iconContainer {
      &.Mui-disabled {
        text {
          fill: $progress-step-next-text-color;
        }

        .MuiSvgIcon-root {
          color: $progress-step-next-circle-color;
        }
      }
    }

    .MuiStepIcon-root {
      width: $progress-step-circle-size;
      height: $progress-step-circle-size;
    }

    .MuiStepIcon-text {
      @include Typography.small_styles($gutter-bottom: false);
      font-weight: Typography.$font-weight-semi-bold;
    }

    .MuiStepLabel-label {
      @include Typography.body-styles($gutter-bottom: false);
      font-weight: Typography.$font-weight-semi-bold;

      &.Mui-active {
        color: $progress-step-active-text-color;
      }

      &.Mui-completed {
        font-weight: Typography.$font-weight-regular;
      }
    }

    .MuiStepConnector-line {
      border-top-width: $progress-step-connector-line-width;
    }
  }
}

.completedIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $progress-step-completed-circle-color;
  color: $progress-step-completed-text-color;
  border-radius: 50%;
  width: $progress-step-circle-size;
  height: $progress-step-circle-size;
}

.toolbar {
  justify-content: space-between;
  padding: Spacing.units(3);
  box-shadow: $toolbar-shadow;
  background-color: colors.$white;
}
