@use '@assuranceiq/react-components/styles/colors';
@use 'styles/modules/Spacing';
@use 'styles/modules/Typography';

.root {
  width: 100%;

  :global {
    .MuiFormGroup-root {
      gap: Spacing.$unit;
    }

    .MuiFormControlLabel-root {
      border: 1px solid colors.$border-light;
      border-radius: Spacing.units(0.5);
      padding: Spacing.$unit;
      margin: 0;
      flex: 1 0 0;

      &:hover {
        border-color: colors.$border-dark;
      }

      &.Mui-disabled {
        border-color: colors.$border-light;
        background-color: colors.$bg-light;

        &:hover {
          border-color: colors.$border-light;
        }
      }
    }

    .RaRadioButtonGroupInput-label {
      transform: unset;
    }

    .RaRadioButtonGroupInput-checked:not(.Mui-disabled) {
      border-color: colors.$active;

      :local .optionText {
        color: colors.$active;
      }

      &:hover {
        border-color: colors.$active-dark;

        :local .optionText {
          color: colors.$active-dark;
        }
      }
    }

    .MuiFormControlLabel-root {
      align-items: stretch;
    }

    .MuiRadio-root {
      align-items: start;
      padding: Spacing.$unit;

      &:hover {
        background: none;
      }
    }
  }
}

.row {
  :global .MuiFormGroup-root {
    flex-wrap: nowrap;
  }
}

.option {
  padding: Spacing.units(0.75) Spacing.$unit;
}

.optionText {
  font-weight: Typography.$font-weight-semi-bold;
  color: colors.$gray-100;
}

.optionSubtext {
  @include Typography.small-styles;
  margin-bottom: 0;
  color: colors.$text-muted;
}
