@use '@assuranceiq/react-components/styles/colors';
@use 'styles/modules/Spacing';

$drawer-width: 75vw;

$btn-size: Spacing.units(4);
$btn-radius: Spacing.units(1);

.drawerPaper {
  width: $drawer-width;
  overflow-y: visible; // Make closeBtn visible

  .closeBtn {
    position: absolute;
    top: Spacing.units(4);
    left: ($btn-size + 1px) * -1;
    width: $btn-size;
    height: $btn-size;
    background-color: colors.$primary;
    border-radius: $btn-radius 0 0 $btn-radius;
  }
}

.arrowIcon {
  font-size: 0.7rem; // Approximate value
  color: colors.$white;
  margin-left: Spacing.units(0.5);
}

.personsShow {
  overflow-y: auto;

  :global {
    .RaShow-main > .MuiPaper-root {
      overflow-y: auto;
    }

    .RaShow-noActions {
      margin-top: 0;

      > .MuiPaper-root {
        border: none;
      }
    }
  }
}
