@use 'styles/modules/Animation';

.listLoading {
  @include Animation.fadeInWithDelay();

  display: flex;

  align-items: center;
  justify-content: center;

  height: 30vh;
}
